import React, { useEffect } from "react";
import { SectionBody } from "./components/SectionBody";
import { Sidebar } from "./components/Sidebar";
import { Reference } from "./components/Reference";
import { observer } from "mobx-react-lite";
import useStores from "./hooks";
import { when } from "mobx";

const App = observer(() => {
  const store = useStores();

  useEffect(() =>
    when(
      () => store.isInPrintMode,
      () => {
        if (typeof window !== "undefined") {
          // Hacky workaround to remove the hacky styling stuff from public/index.html
          window.document
            .getElementById("co2-calculator-root")
            ?.removeAttribute("style");
        }
      },
    ),
  );

  useEffect(() => {
    if (store.isPrintReady) {
      window["__CO2CAlC_PRINT_READY__"] = true;
    }
  }, [store.isPrintReady]);

  if (store.isInPrintMode) {
    return (
      <div className="font-copy flex relative flex-grow flex-col bg-white text-gray-800">
        <div className="flex flex-col flex-grow overflow-hidden h-f md:flex-row items-stretch">
          <SectionBody />
        </div>
      </div>
    );
  }

  return (
    <div className="font-copy flex relative flex-grow flex-col shadow-md rounded-md bg-white text-gray-800">
      <div className="flex flex-col flex-grow overflow-hidden h-f md:flex-row items-stretch">
        <Sidebar />
        <SectionBody />
        <div className="md:hidden">
          <Reference />
        </div>
      </div>
    </div>
  );
});

export default App;
