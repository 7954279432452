class EmbedApi {
  static get embeddingContext() {
    const q = new URLSearchParams(window.location.search);
    const client_id = q.get("clientID") || "";

    return {
      origin: window.location.origin,
      client_id,
      referrer: EmbedApi.isEmbedded ? window.document.referrer : "",
    };
  }

  static get isEmbedded() {
    return window.parent !== window;
  }

  private parentWindow: WindowProxy | null = null;
  private parentOrigin: string | null = null;

  private _connected = false;
  get connected() {
    return this._connected;
  }

  constructor() {
    this._setup();
  }

  trigger(type: string, data: any) {
    this.postMessage({
      type: `event:${type}`,
      data,
    });
  }

  // --- private ---

  private _setup() {
    window.addEventListener("message", this._messageHandler.bind(this), false);
    window.parent.postMessage({ type: "reset" }, "*");
  }

  private _messageHandler(ev: MessageEvent<any>) {
    if (!this.connected) {
      return this._connect(ev);
    }

    if (ev.source !== this.parentWindow) return;
    if (ev.origin !== this.parentOrigin) return;

    // TODO: implement
    console.debug("CO2-EMBEDDED#messageHandler", ev.data);
  }

  private _connect(ev: MessageEvent<any>) {
    console.log("CO2-EMBEDDED#connect", ev.data);
    if (
      !ev.data ||
      typeof ev.data !== "object" ||
      typeof ev.data.type !== "string" ||
      ev.data.type !== "connect"
    ) {
      console.log("discard");
      return;
    }

    if (typeof ev.data.client_id !== "string") {
      console.error("connect message must contain the `client_id` field.");
      return;
    }

    const client_id = ev.data.client_id;
    const origin = ev.origin;
    // TODO: check [client_id] against a known list and check if [origin] is allowed for this [client_id]
    console.log(
      "CO2-EMBEDDED#connect",
      "establishing connection to (client_id, origin)",
      client_id,
      origin,
    );

    this.parentOrigin = origin;
    this.parentWindow = ev.source as WindowProxy;
    this._connected = true;

    // FIXME: this simulates an async fetch call
    window.setTimeout(() => {
      this.postMessage({
        type: "connected",
      });
    }, 2000);
  }

  private postMessage(msg: any) {
    if (!this.parentWindow || !this.parentOrigin) {
      console.warn("Not connected. Call CO2Calculator.connect() first.");
      return;
    }
    this.parentWindow.postMessage(msg, this.parentOrigin);
  }
}

export default EmbedApi;
