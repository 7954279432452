import { observer } from "mobx-react-lite";
import React from "react";
import useStores from "../hooks";
import { SectionModel } from "../types";
import { asset } from "../util/assets";
import { ProgressBar } from "./ProgressBar";
import { Toggle } from "./Toggle";
import { H1 } from "./typo";
var classNames = require("classnames");

interface Props {
  sectionKey: string;
}

export const SectionOverview = observer(({ sectionKey }: Props) => {
  const store = useStores();
  const section: SectionModel = store.data[sectionKey];
  const isActive = sectionKey === store.activeSectionKey;

  const emission = store.calculateSectionEmission(sectionKey);

  const progressPercentage =
    (100 * (emission - section.minReference)) /
    (section.maxReference - section.minReference);

  const disabled: boolean = !!section.value;

  return (
    <a
      href="#"
      onClick={
        section.value ? () => null : () => store.goToNamedSection(sectionKey)
      }
      className=""
    >
      <div
        className={classNames(
          "flex-col space-y-2 md:block md:p-4 p-1 mb-4 md:mb-0 rounded-lg",
          {
            "md:bg-gray-100": isActive,
            hidden: !isActive,
          },
        )}
      >
        <div className="flex flex-row flex-wrap space-x-4 items-center">
          <img
            src={asset(`icons/${section.icon}.svg`)}
            alt="The Section Icon"
            className="max-h-6"
          ></img>
          <div className={classNames("flex-1")}>
            <H1 disabled={disabled}>{section.title}</H1>
          </div>
          {/* <h6 className="font-semibold">2/6</h6> */}
        </div>

        <ProgressBar
          progress={section.value ? 0 : progressPercentage}
          isActive={isActive}
        ></ProgressBar>

        <div className="flex flex-row items-center ">
          <H1 disabled={disabled}>{`${emission.toFixed(2)}`}</H1>
          <span className="text-gray-500 text-xs ml-2">
            Tonnen CO2 Äquivalente
          </span>
        </div>
      </div>
    </a>
  );
});
