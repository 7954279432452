import { EmmaConfig } from "./flavor";

const EMMA_FORM_CONFIG = {
  action: "https://mailing.sparkasse.de/optin/optin/execute",
  method: "POST",
  charset: "utf-8",
  enctype: "application/x-www-form-urlencoded",
};

const TIMER_FIELD = ["ic", (n) => n * 17] as const;

const USER_FIELD_MAP = {
  email: "fields[1]",
};

export interface EmmaData {
  email: string;
}

function prepareFormData(
  data: EmmaData,
  timeMounted: number,
  config: EmmaConfig,
) {
  const fd = new URLSearchParams();
  const fields = Object.entries(config);

  for (const field of fields) {
    fd.append(field[0], field[1]);
  }

  const secondsPassed = Math.floor((Date.now() - timeMounted) / 1000);
  fd.append(TIMER_FIELD[0], TIMER_FIELD[1](secondsPassed).toFixed(0));
  fd.append(USER_FIELD_MAP.email, data.email);

  return fd;
}

/**
 *
 * @param data email and preference
 * @param timeMounted unix timestamp of the form mount time
 */
export async function submitEmma(
  data: EmmaData,
  timeMounted: number,
  config: EmmaConfig,
) {
  const fd = prepareFormData(data, timeMounted, config);

  const { action, method, enctype } = EMMA_FORM_CONFIG;
  const response = await fetch(action, {
    mode: "no-cors",
    referrerPolicy: "no-referrer",
    method,
    headers: {
      "Content-Type": enctype,
    },
    body: fd,
  });

  if (response.status !== 200) {
    console.error(`EMMA returned a http error code (${response.status})`);
  }
}
