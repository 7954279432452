export interface EmmaConfig {
  account_id: string;
  account_code: string;
  optinsetup_id: string;
  optinsetup_code: string;
}

type Flavor = "zusammen_nachhaltig" | "skwh" | "default";

const CLIENT_ID_MAP: Record<string, Flavor> = {
  nf6NHpS3eP3YAQB: "zusammen_nachhaltig",
  JjOjFSdPrGizLBf: "skwh",
};

export const EMMA_CONFIG_MAP: Record<Flavor, EmmaConfig | undefined> = {
  zusammen_nachhaltig: {
    account_id: "16502",
    account_code: "2cH1Z",
    optinsetup_id: "22",
    optinsetup_code: "w8mbg",
  },
  skwh: {
    account_id: "8379",
    account_code: "8x5MS",
    optinsetup_code: "yLnXr",
    optinsetup_id: "1",
  },
  default: undefined,
};

export function resolveFlavour(clientId: string | undefined): Flavor {
  if (!clientId) {
    return "default";
  }

  const flavour = CLIENT_ID_MAP[clientId];

  if (!flavour) {
    return "default";
  }

  return flavour;
}

export function getEmmaConfig(clientId: string | undefined): EmmaConfig | undefined {
  const flavour = resolveFlavour(clientId);
  return EMMA_CONFIG_MAP[flavour];
}
