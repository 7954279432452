import { observer } from "mobx-react-lite";
import useStores from "../../hooks";
import { hints } from "../../data/hints";
import { H1 } from "../typo";
import classNames from "classnames";
import { Fragment } from "react";

interface Props {
  sectionKey: string;
}
const Tab = (props: {
  title: string;
  onClick: () => void;
  isActive?: boolean;
}) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames(
        "px-3 py-2 font-semibold text-md rounded-md",
        {
          "bg-gray-100 text-gray-800 hover:bg-gray-200": !props.isActive,
          "bg-green-600 hover:bg-green-700 text-green-50": props.isActive,
        }
      )}
    >
      {props.title}
    </button>
  );
};

export const Recommendations = observer(({ sectionKey }: Props) => {
  const store = useStores();

  const filteredHints = hints[sectionKey].filter(
    (hint) => hint.condition && hint.condition!(store.data),
  );

  return (
    <div className="pb-4">
      {Object.keys(filteredHints!).map((key) => (
        <Fragment key={key}>
          <Tab
            onClick={() => void 0}
            title={filteredHints![key].title}
            isActive
          ></Tab>
          <div className="bg-white shadow-sm my-4 p-4">
            {filteredHints![key].hints.map((hint) => (
              <div className="flex flex-row items-start my-4">
                <div className="pt-0.5 flex">{hint.text}</div>
              </div>
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
});
