import { flow, makeAutoObservable } from "mobx";
import { DataStore } from ".";
import EmbedApi from "../embed-api";
import { data2json } from "../util";
import { submitEmma } from "../util/emma";
import { EMMA_CONFIG_MAP, getEmmaConfig, resolveFlavour } from "../util/flavor";

export class ConversionDialogStore {
  private readonly dataStore: DataStore;

  state: "idle" | "loading" | "error" | "success" = "idle";

  constructor(dataStore: DataStore) {
    this.dataStore = dataStore;
    makeAutoObservable(
      this,
      {
        submit: flow,
      },
      { autoBind: true },
    );
  }

  get isLoading() {
    return this.state === "loading";
  }
  get isDone() {
    return this.state === "success";
  }
  get isError() {
    return this.state === "error";
  }

  *submit(
    email: string,
    subscribeNewsletter: boolean = false,
    timeMounted: number = 0,
    clientId: string | undefined = undefined,
  ) {
    if (this.state === "loading") return;
    this.state = "loading";

    try {
      yield this.requestPdfReport(email, subscribeNewsletter);
      this.state = "success";
    } catch (err) {
      // TODO: distinguish internal and network errors
      this.state = "error";
    }

    const emmaConfig = getEmmaConfig(clientId);
      if (emmaConfig) {
        submitEmma({ email }, timeMounted, emmaConfig).finally(() => {
          /* swallow errors */
        });
      }
  }

  private async requestPdfReport(
    email: string,
    subscribeNewsletter: boolean = false,
  ): Promise<void> {
    const response = await fetch(`/pdf-service`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          email,
          subscribeNewsletter,
          values: data2json(this.dataStore.data),
        },
        context: EmbedApi.embeddingContext,
      }),
      credentials: 'omit',
      mode: 'cors',
      cache: 'no-cache',
    });

    if (response.status !== 200) {
      throw new Error("failed calling function `requestPdfReport`");
    }

    const data = await response.json();
    if (!data || !data.success) {
      throw new Error("failed calling function `requestPdfReport`");
    }
  }
}
