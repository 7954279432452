import { isSliderInput } from "../util";
import { DataModel, ValueModel } from "../types";

export const calculateEffectiveFactor = (value: ValueModel) => {
  const range = isSliderInput(value.input)
    ? value.input.max - value.input.min
    : value.input.answers.length;
  return (
    (value.value - (value.baseValue ?? 0)) * (1 / range) * (value.factor ?? 1)
  );
};

export const data: DataModel = {
  living: {
    title: "Wohnen",
    icon: "icon-home",
    average: 2.74,
    averageLocal: 2.3,
    minReference: 0,
    maxReference: 5,
    hint: "This is a cool hint",
    values: {
      householdMembers: {
        title: "Anzahl der Personen in Ihrem Haushalt",
        value: 2,
        baseValue: 1,
        factor: 5,
        input: { min: 1, max: 10 },
      },
      livingArea: {
        title: "Größe der Wohnfläche",
        value: 80,
        baseValue: 60,
        factor: 0.8,
        unit: "m²",
        input: { min: 20, max: 200 },
      },
      photovoltaics: {
        title:
          "Falls Sie eine Photovoltaik-Anlage nutzen, wie groß ist die Fläche?",
        value: 0,
        emission: -0.047,
        unit: "m²",
        input: { min: 0, max: 60 },
        footprint: (data: DataModel, value: ValueModel) => {
          return value.emission! * value.value;
        },
      },
      electricityConsumption: {
        title: "Ihr durchschnittlicher Jahresverbrauch (Strom)",
        value: 2,
        baseValue: 2,
        factor: 0.7,
        input: {
          isCondensed: true,
          answers: [
            { text: "sehr sparsam" },
            { text: "eher niedrig" },
            { text: "durchschnittlich" },
            { text: "sehr hoch" },
          ],
        },
        hint: "sehr sparsam: unter 1.000 kWh (Wohnung) oder unter 1.800 kWh (Haus) </br> eher niedrig: unter 1.200 kWh (Wohnung) oder unter 2.000 kWh (Haus) </br> durchschnittlich: unter 1.700 kWh (Wohnung) oder unter 2.500 kWh (Haus) </br> sehr hoch: über 1.700 kWh (Wohnung) oder über 2.500 kWh (Haus)",
      },
      electricityType: {
        title: "Aus welcher Quelle wird der Strom bezogen?",
        value: 2,
        factor: 1.9,
        baseValue: 1,
        emission: 1.52,
        input: {
          isCondensed: true,
          answers: [
            { text: "Ökostrom" },
            { text: "Strommix" },
            { text: "Fossil" },
          ],
        },
        hint: "Ökostrom: 100 % unabhängig von Kohle- und Atomkonzernen. </br> Strommix: 50% erneuerbar, 50% fossil </br> Fossil: reine Stromgewinnung aus Kohle, Kernenergie und Erdgas",
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.living.values;
          return (
            (value.emission! *
              (1 + calculateEffectiveFactor(value)) *
              (1 +
                calculateEffectiveFactor(
                  values.householdMembers as ValueModel,
                )) *
              (1 + calculateEffectiveFactor(values.livingArea as ValueModel)) *
              (1 +
                calculateEffectiveFactor(
                  values.electricityConsumption as ValueModel,
                ))) /
            (values.householdMembers as ValueModel).value
          );
        },
      },

      heatingType: {
        title: "Art der Heizung",
        value: 0,
        emission: 0.59,
        input: {
          isCondensed: true,
          answers: [
            { text: "Erdgas" },
            { text: "Ölheizung" },
            { text: "Andere" },
            { text: "Stromheizung" },
          ],
        },
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.living.values;
          if (value.value === 3) {
            return (
              (value.emission! *
                (1 + calculateEffectiveFactor(value)) *
                (1 +
                  calculateEffectiveFactor(
                    values.householdMembers as ValueModel,
                  )) *
                (1 +
                  calculateEffectiveFactor(values.livingArea as ValueModel)) *
                (1 +
                  calculateEffectiveFactor(
                    values.heatingPreferences as ValueModel,
                  )) *
                (1 +
                  calculateEffectiveFactor(
                    values.electricityType as ValueModel,
                  ))) /
              (values.householdMembers as ValueModel).value
            );
          }
          return (
            (value.emission! *
              (1 + calculateEffectiveFactor(value)) *
              (1 +
                calculateEffectiveFactor(
                  values.householdMembers as ValueModel,
                )) *
              (1 + calculateEffectiveFactor(values.livingArea as ValueModel)) *
              (1 +
                calculateEffectiveFactor(
                  values.heatingPreferences as ValueModel,
                ))) /
            (values.householdMembers as ValueModel).value
          );
        },
      },
      heatingPreferences: {
        title: "Wie sind Ihre Heizvorlieben?",
        value: 1,
        baseValue: 1,
        factor: 0.6,
        input: {
          isCondensed: true,
          answers: [
            { text: "sehr kalt", subtext: "15-16°" },
            { text: "eher kalt", subtext: "17-19°" },
            { text: "eher warm", subtext: "20-22°" },
            { text: "sehr warm", subtext: "23-25°" },
          ],
        },
      },
    },
  },
  nutrition: {
    title: "Ernährung",
    icon: "icon-food",
    average: 1.69,
    minReference: 0,
    maxReference: 3.3,
    values: {
      age: {
        title: "Alter",
        value: 1,
        baseValue: 1,
        factor: -0.2,
        input: {
          isCondensed: true,
          answers: [
            { text: "< 25 Jahre" },
            { text: "25 - 40 Jahre" },
            { text: "40 - 60 Jahre" },
            { text: "> 60 Jahre" },
          ],
        },
        hint: "Im Alter sinkt Ihr Kalorienbedarf und somit auch Ihr CO2-Verbrauch",
      },
      gender: {
        title: "Geschlecht",
        value: 0,
        baseValue: 0,
        factor: 0.1,
        input: {
          isCondensed: true,
          answers: [
            { text: "weiblich" },
            { text: "divers" },
            { text: "männlich" },
          ],
        },
        hint: "Männer haben von Natur aus einen höheren Kalorienbedarf als Frauen",
      },
      fitness: {
        title: "Wie ist Ihr Aktivitätslevel?",
        value: 1,
        baseValue: 1,
        factor: 0.35,
        input: {
          isCondensed: true,
          answers: [
            { text: "ziemlich bequem" },
            { text: "durchschnittlich" },
            { text: "sportlich" },
            { text: "super sportlich" },
          ],
        },
        hint: "Je sportlicher ein Mensch ist, desto höher ist sein Bedarf an Kalorien und somit sein Lebensmittel-Verbrauch",
      },

      nutritionMode: {
        title: "Wie ernähren Sie sich?",
        value: 2,
        emission: 1.18,
        input: {
          answers: [
            {
              text: "vegan",
              subtext: "keinerlei tierische Produkte oder Nebenprodukte",
            },
            {
              text: "vegetarisch",
              subtext: "kein Fleisch, aber andere tierische Produkte",
            },
            {
              text: "durchschnittlicher Fleischkonsum",
              subtext: "2-3 Mal die Woche Fleisch, wenn möglich Bio",
            },
            { text: "eher viel Fleisch", subtext: "3-4 Mal die Woche Fleisch" },
            {
              text: "Fleisch ist mein Gemüse",
              subtext:
                "mehrmals täglich Fleisch, ohne Rücksicht auf Herkunft und Haltung",
            },
          ],
        },
        hint: "Viehhaltung ist weltweit für fast 20% aller Treibhausgasemissionen zuständig. </br> Der Konsum von Fleisch und tierischen Produkten wirkt sich somit unmittelbar auf Ihren CO2-Verbrauch aus.",
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.nutrition.values;
          return (
            value.emission! *
            (1 + calculateEffectiveFactor(value)) *
            (1 + calculateEffectiveFactor(values.age as ValueModel)) *
            (1 + calculateEffectiveFactor(values.gender as ValueModel)) *
            (1 + calculateEffectiveFactor(values.fitness as ValueModel)) *
            (1 +
              calculateEffectiveFactor(values.regionalSaisonal as ValueModel)) *
            (1 + calculateEffectiveFactor(values.bio as ValueModel))
          );
        },
      },
      regionalSaisonal: {
        title: "Wie häufig kaufen Sie regionale und saisonale Produkte?",
        value: 1,
        baseValue: 1,
        factor: -0.6,
        input: {
          isCondensed: true,
          answers: [
            { text: "eher selten" },
            { text: "ab und zu" },
            { text: "meistens" },
            { text: "wenn möglich immer" },
          ],
        },
        hint: "Weite Transportwege von Lebensmitteln generieren sehr viel CO2 und a-saisonale Pflanzungen verbrauchen enorm viele Ressourcen.",
      },
      bio: {
        title: "Wie häufig kaufen Sie Bio-Produkte?",
        value: 1,
        baseValue: 1,
        factor: -0.2,
        input: {
          isCondensed: true,
          answers: [
            { text: "eher selten" },
            { text: "ab und zu" },
            { text: "meistens" },
            { text: "wenn möglich immer" },
          ],
        },
      },
    },
  },
  mobility: {
    title: "Mobilität",
    icon: "icon-mobility",
    average: 2.09,
    minReference: 0,
    maxReference: 5,
    hint: "This is a cool hint",
    values: {
      transport: {
        title: "Transportmittel",
        subtitle: "Wie bewegen Sie sich fort?",
        values: {
          car: {
            isEnabled: true,
            title: "Auto",
            subtitle: "Fahrleistung mit dem eigenen Auto",
            value: 180,
            emission: 0.012,
            unit: "km/Woche",
            hasElectricityOption: true,
            input: { min: 0, max: 1500 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (
                (value.isElectric ? 0.1 : 1) * value.emission! * value.value
              );
            },
          },
          carsharing: {
            title: "Carsharing oder Fahrgemeinschaften",
            subtitle:
              "Fahrleistung  mit Carsharing-Autos oder Fahrgemeinschaften",
            value: 0,
            emission: 0.0067,
            unit: "km/Woche",
            hasElectricityOption: true,
            input: { min: 0, max: 1500 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (
                (value.isElectric ? 0.1 : 1) * value.emission! * value.value
              );
            },
          },
          motorcycle: {
            title: "Motorrad / Motorroller",
            subtitle: "Fahrleistung mit dem Motorrad / Motorroller",
            value: 0,
            emission: 0.004,
            unit: "km/Woche",
            hasElectricityOption: true,
            input: { min: 0, max: 1000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return (
                (value.isElectric ? 0.1 : 1) * value.emission! * value.value
              );
            },
          },
          publicTransportation: {
            title: "Öffentliche Verkehrsmitteln",
            subtitle: "Fahrleistung mit den öffentlichen Verkehrsmitteln",
            value: 0,
            emission: 0.0026,
            unit: "km/Woche",
            input: { min: 0, max: 1000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          train: {
            title: "Zugfahrten",
            subtitle: "Fahrleistung - Zugfahrten",
            value: 0,
            emission: 0.0017,
            unit: "km/Woche",
            input: { min: 0, max: 1000 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          bicycle: {
            title: "Fahrleistung mit dem Fahrrad",
            subtitle: "Fahrleistung mit dem Fahrrad",
            value: 0,
            unit: "km/Woche",
            hasElectricityOption: true,
            input: { min: 0, max: 1000 },
          },
        },
      },
      continentalFlights: {
        title: "Flugreisen innerhalb Europas",
        value: 0,
        baseValue: 0,
        emission: 0.154,
        unit: "Std/Jahr",
        input: { min: 0, max: 200 },
        footprint: (data: DataModel, value: ValueModel) => {
          return value.emission! * value.value;
        },
      },
      intercontinentalFlights: {
        title: "Interkontinentale Flugreisen",
        value: 0,
        baseValue: 0,
        emission: 0.166,
        unit: "Std/Jahr",
        input: { min: 0, max: 200 },
        footprint: (data: DataModel, value: ValueModel) => {
          return value.emission! * value.value;
        },
      },
    },
  },
  consumption: {
    title: "Konsum",
    icon: "icon-shopping",
    average: 3.79,
    minReference: 0,
    maxReference: 6.5,
    hint: "This is a cool hint",
    values: {
      expenses: {
        title:
          "Höhe der monatlichen Ausgaben - abzüglich Miete und Lebensmittel?",
        subtitle:
          "enthält: Restaurant, Kino, Kleidung und Schuhe, technische Ausstattung, Möbel usw.",
        unit: "€",
        value: 500,
        emission: 0.0064,
        input: { min: 0, max: 2500 },
        footprint: (data: DataModel, value: ValueModel) => {
          const values = data.consumption.values;
          return (
            value.emission! *
            value.value *
            (1 +
              calculateEffectiveFactor(
                values.consumptionBehavior as ValueModel,
              )) *
            (1 + calculateEffectiveFactor(values.secondHand as ValueModel))
          );
        },
      },
      consumptionBehavior: {
        title: "Wie schätzen Sie Ihr Konsumverhalten ein?",
        subtitle:
          "Konsumieren Sie sparsam oder großzügig? Legen Sie Wert auf Langlebigkeit und Qualität oder auf günstige Preise, unabhängig von Herstellungskriterien?",
        value: 2,
        baseValue: 2,
        factor: 0.24,
        input: {
          answers: [
            {
              text: "ich kaufe fast immer nachhaltig, umweltbewusst und fair trade ein",
            },
            { text: "ich versuche nachhaltig und umweltbewusst einzukaufen" },
            { text: "könnte besser sein" },
            { text: "ist mir egal" },
          ],
        },
      },
      secondHand: {
        title:
          "Wie häufig kaufen Sie Second Hand (Kleidung, Möbel, technische Geräte)?",
        value: 2,
        baseValue: 2,
        factor: 0.2,
        input: {
          answers: [
            { text: "immer" },
            { text: "meistens" },
            { text: "ab und zu" },
            { text: "nie" },
          ],
        },
        hint: "Je länger Konsumgüter leben dürfen, desto besser ist das für die Umwelt. </br> Bereits produzierte Waren verbrauchen im Weiterverkauf außerdem kaum CO2, lediglich für Transporte fallen dann noch Verbräuche an.",
      },
    },
  },
  hobbies: {
    title: "Hobbies",
    icon: "icon-hobbies",
    average: 0.9,
    minReference: 0,
    maxReference: 2.5,
    hint: "This is a cool hint",
    values: {
      pets: {
        title: "Welche Haustiere haben Sie?",
        hint: "Die Rohstoffe für Futter samt Herstellung, Verpackung und Transport sowie die CO2 Belastung durch Exkremente tragen nicht unmaßgeblich zu Ihrer persönlichen Ökobilanz bei.",
        values: {
          fishes: {
            title: "Fische",
            subtitle: "Wie groß ist Ihr Aquarium (Wasservolumen)?",
            value: 0,
            emission: 0.00081,
            unit: "Liter",
            input: { min: 0, max: 800 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          rodents: {
            title: "Nagetier",
            subtitle: "Wie viele Nagetiere haben Sie?",
            value: 0,
            emission: 0.12,
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          cats: {
            title: "Katze",
            subtitle: "Wie viele Katzen haben Sie?",
            value: 0,
            emission: 0.38,
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          dogs: {
            title: "Hund",
            subtitle: "Wie viele Hunde haben Sie?",
            value: 0,
            emission: 0.8,
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          horses: {
            title: "Pferd",
            subtitle: "Wie viele Pferde haben Sie?",
            value: 0,
            emission: 2.36,
            input: { min: 0, max: 10 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
        },
      },
      holiday: {
        title: "Wie machen Sie Urlaub?",
        values: {
          camping: {
            title: "Camping-Urlaub",
            subtitle: "Zeit im Camping-Urlaub",
            value: 0,
            emission: 0.027,
            unit: "Tage/Jahr",
            input: { min: 0, max: 70 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          airbnb: {
            isEnabled: true,
            title: "Urlaub in Airbnb-Wohnungen/Ferienwohnungen",
            subtitle: "Urlaubszeit in Airbnb-Wohnungen/Ferienwohnungen",
            value: 14,
            emission: 0.052,
            unit: "Tage/Jahr",
            input: { min: 0, max: 70 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          hotel: {
            title: "Urlaub im Hotel/Appartment mit Frühstück oder Halbpension",
            subtitle:
              "Urlaubszeit im Hotel/Appartment mit Frühstück oder Halbpension",
            value: 0,
            emission: 0.059,
            unit: "Tage/Jahr",
            input: { min: 0, max: 70 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          allInclusive: {
            title: "All-Inclusive-Urlaub im Hotel",
            subtitle: "Zeit im All-Inclusive-Urlaub im Hotel",
            value: 0,
            emission: 0.082,
            unit: "Tage/Jahr",
            input: { min: 0, max: 70 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
          },
          cruise: {
            title: "Kreuzfahrt-Reisen",
            subtitle: "Urlaubszeit auf Kreuzfahrt-Reisen",
            value: 0,
            emission: 0.106,
            unit: "Tage/Jahr",
            input: { min: 0, max: 40 },
            footprint: (data: DataModel, value: ValueModel) => {
              return value.emission! * value.value;
            },
            hint: "Auf einer siebentägigen Kreuzfahrt auf einem Schiff mit 2000- 3000 Passagieren zu zweit in einer Standardkabine sind Sie als einzelner Gast für 1,5 Tonnen CO2 verantwortlich. </br> Um 1,5 Tonnen CO2 aufzunehmen muss eine Buche 120 Jahre lang wachsen.",
          },
        },
      },
      streaming: {
        title: "Streaming von Filmen, Serien und Mediatheken",
        value: 5,
        emission: 0.025,
        unit: "Stunden/Woche",
        input: { min: 0, max: 50 },
        footprint: (data: DataModel, value: ValueModel) => {
          return value.emission! * value.value;
        },
        hint: "Rohstoffbedarf, Energiebedarf und Wasserverbrauch eines Rechenzentrums sind nicht zu unterschätzen. </br> Vor allem die Klimaanlagen, die die Rechenzentren dauerhaft auf 25 Grad runterkühlen müssen, damit diese nicht überhitzen, fallen ins Gewicht.",
      },
    },
  },
  public: {
    title: "Öffentliche Emissionen",
    average: 1.1,
    icon: "icon-public",
    minReference: 0,
    maxReference: 1,
    hint: "Erklärung zu öffentlichen Emissionen - wie entstehen sie und wie könnten wir als Individuen etwas dazu beitragen, sie zu verbessern?",
    value: 1.1,
    values: {},
  },
};
